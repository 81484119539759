import React from 'react';
import SimpleAlertDialog, { ModalButton } from '../common/SimpleAlertDialog';
import { API } from '../api/APIClient'
import Axios from 'axios'


class LoginScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false,
            username: "",
            password: "",
            modalOpen: false,
            modalTitle: "",
            modalMessage: "",
            modalButtons: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.showAlertModal = this.showAlertModal.bind(this);
    }



    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    loginClicked() {
        if (this.state.username.length > 0 && this.state.password.length > 0) {
            this.setState({
                submitting:true,
                submitMessage:"Logging in..."
            });
            const formData = new FormData();
            formData.append('username', this.state.username);
            formData.append('password', this.state.password);

            Axios
                .post(API.user.login, formData)
                .then((res) => {

                    this.setState({
                        submitting:false,
                        submitMessage:""
                    });
                    if (res.data.success) {

                        var user = res.data.data.user;
                        user.token = res.data.data.token;

                        this.props.loginHandler(user);

                    }

                }).catch(error => {

                this.setState({
                    submitting:false,
                    submitMessage:""
                });

                console.log(error.response)
                if (error.response.data.error) {

                     this.showAlertModal(
                        "Error",
                        (<div>{error.response.data.error}</div>),
                        [{ label: "OK", onClick: () => this.dismissAlertModal() }]
                    );
                } else{
                    this.showAlertModal(
                        "Error",
                        (<div>Could not log in.  Please check connection and try again.</div>),
                        [{ label: "OK", onClick: () => this.dismissAlertModal() }]
                    );
                }
            });
        } else {
            this.showAlertModal(
                "Error",
                (<div>Please enter your username and password.</div>),
                [{ label: "OK", onClick: () => this.dismissAlertModal() }]
            );
        }
    }

    showAlertModal(title, message, buttons) {
        this.setState({
            modalOpen: true,
            modalTitle: title,
            modalMessage: message,
            modalButtons: buttons
        });
    }

    dismissAlertModal() {
        this.setState({
            modalOpen: false
        });
    }

    render() {
        return (
            <div className="loginMain">
                <div className="login-box">
                    <div className="big-logo"></div>
                    <div className="main-wrap">
                        <div className="cell small-12">
                            <label>
                                <span className="login-label">Username</span>
                                <input
                                    className="login-field"
                                    type="text"
                                    name="username"
                                    placeholder="Username"
                                    value={this.state.username}
                                    onChange={this.handleChange}
                                />
                            </label>
                        </div>
                        <div className="spacer"></div>
                        <div className="cell small-12">
                            <label>
                                <span className="login-label">Password</span>
                                <input
                                    className="login-field"
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                />
                            </label>
                        </div>
                        <div>
                            <div
                                onClick={() => this.loginClicked()}
                                className="ga-button"
                            >
                                Login
                            </div>
                            <div>
                                {this.state.submitting && <div>{this.state.submitMessage}</div>}
                            </div>
                        </div>
                    </div>
                </div>

                <SimpleAlertDialog
                    open={this.state.modalOpen}
                    title={this.state.modalTitle}
                    message={this.state.modalMessage}
                >
                    {
                        this.state.modalButtons.map((button, index) => (
                            <ModalButton
                                key={index} // Add a key when mapping
                                label={button.label}
                                onClick={button.onClick}
                                className={button.className}
                            />
                        ))
                    }
                </SimpleAlertDialog>
            </div>
        );
    }
}

export default LoginScreen;
