import React from 'react';
import { API } from '../api/APIClient'
import Axios from 'axios'
import {Link} from "react-router-dom";



class GasCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cardType : "PHOTOS",
            cardData: [],
            rejectedIds: []
        };
        this.handleChange = this.handleChange.bind(this);

    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

         this.setState({
            [name]: value
        }, () => {
             this.getCards();
        });


    }


    componentDidMount()
    {
        this.getCards();
    }


    getCards()
    {
        const formData = new FormData();
        formData.append('typeToShow', this.state.cardType);
        formData.append('count', 5);
        Axios
            .post(API.cards.getCards, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        cardData: res.data.data

                    })
                }
            }).catch(error => {

        });
    }


    cardClicked(theId) {

        this.setState((prevState) => {
             if (prevState.rejectedIds.includes(theId)) {
                 return {
                    rejectedIds: prevState.rejectedIds.filter(id => id !== theId)
                };
            } else {
                 return {
                    rejectedIds: [...prevState.rejectedIds, theId]
                };
            }
        });

    }

    moderate() {

        const { cardData, rejectedIds } = this.state;

        // Get all card IDs as "seen"
        const seenIds = cardData.map(card => card.id);

        const formData = new FormData();

        formData.append('postIds', (JSON.stringify(rejectedIds)));  // rejectedIds array encoded

        formData.append('seen', (JSON.stringify(seenIds)));  // All card ids encoded

        formData.append('status', 0);

        // Send data to the server
        Axios
            .post(API.cards.moderateCards, formData)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        rejectedIds: []
                    });
                    this.getCards();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    moderateSingle(theId, approve){

        const seenIds = [theId];

        const formData = new FormData();

        if (approve) {
            formData.append('postIds', (JSON.stringify([])));
        } else {
            formData.append('postIds', (JSON.stringify( [theId])))
        }

        formData.append('seen', (JSON.stringify(seenIds)));

        formData.append('status', 0);

        // Send data to the server
        Axios
            .post(API.cards.moderateCards, formData)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        rejectedIds: []
                    });
                    this.getCards();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


     copyToClipboard(text) {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard');
             })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    }

    goHome() {

    }

    render() {
        return (
            <div className="gas-cards">
                <header className="header">
                    <Link to={"/"} ><div className="menu-icon"  >
                         <i className="fas fa-home"></i>
                    </div></Link>

                    <div className="big-logo"></div>

                    <div className="logout-icon" onClick={this.props.logoutHandler}>
                        {/* Logout icon */}
                        <i className="fas fa-sign-out-alt"></i>
                    </div>
                </header>

                <div  className="gas-cards-content">
                    <h1>Gas Card Review</h1>
                    <select value={this.state.cardType} name="cardType" onChange={this.handleChange}>
                        <option key="PHOTOS" value="PHOTOS">Card Photos</option>
                        <option key="NUMBERS" value="NUMBERS">Card Numbers</option>
                    </select>


                    {(this.state.cardType === "PHOTOS") ?
                        <div className="cards">
                            {this.state.cardData.map((card) => {

                                return (<div className="photo-card" onClick={() => this.cardClicked(card.id)}>
                                    {this.state.rejectedIds.includes(card.id)  ? <span className="reject">Reject</span> : <span  className="approve">Approve</span>}
                                    <img src={card.file_path}/>
                                </div>);
                            })}
                            <div className="ga-button " onClick={() => this.moderate()} >Save</div>
                        </div>
                    :

                        <div  className="number-cards">
                            {this.state.cardData.map((card) => {

                                return (<div className="number-card">
                                    <div>Card No: {card.typedNumber}</div>
                                    <div>{card.givenName} {card.familyName}</div>
                                    <div>{card.email}</div>

                                    <div className="button-row " >
                                        <div className="ga-button" onClick={() => this.moderateSingle(card.id, true)}>Approve</div>
                                        <div  className="ga-button"  style={{background:"#D85946"}} onClick={() => this.moderateSingle(card.id, true)}>Reject</div>
                                        <div  className="ga-button"  style={{background:"#999"}} onClick={() => this.copyToClipboard(card.typedNumber)}>Copy&nbsp;Number</div>
                                    </div>
                                </div>);
                            })}
                        </div>

                    }

                </div>

            </div>
        );
    }
}

export default GasCard;
