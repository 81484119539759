import axios from 'axios'

//LIVE
const BASE_URL = "https://gasapp.app-cms.com/mobile-cms/";
//DEVconst BASE_URL = "http://gasappdev.app-cms.com/mobile-cms/";
const VERSION = "1.0.0";
axios.defaults.headers.common['appplatform'] = 'mobile-cms';
axios.defaults.headers.common['appversion'] = VERSION;

axios.defaults.baseURL = BASE_URL;

export const APIHelper = (function(axios){
    return {
        setAuthDetails: (token, userID) => {
            axios.defaults.headers.common['token'] = token;
            axios.defaults.headers.common['userId'] = userID;
        },
        removeAuthDetails: () => {
            delete axios.defaults.headers.common['token'];
            delete axios.defaults.headers.common['userId'];
        },
        onAuthFailed: (callback) => {
            if (callback instanceof Function) {
                axios.defaults.validateStatus = (status) => {
                    if (status === 401 || status === 403) {
                        callback();
                    }
                    else {
                        return status;
                    }
                }
            }
        },
        parse : (resp) => {
            let out = {
                "success" : false,
                "data" : null,
                "error" : "An unknown error has occurred"
            };

            if (resp !== undefined) {
                if (resp.data.hasOwnProperty("success")) {
                    out.success = resp.data.success;
                }

                if (resp.data.hasOwnProperty("error")) {
                    out.error = resp.data.error;
                }

                if (resp.data.hasOwnProperty("data")) {
                    out.data = resp.data.data;
                }
            }

            return out;
        }
    }
})(axios);


export const API = {
    BASE_URL: BASE_URL,
    user: {
        login: "user/login",
        getDashData : "user/getDashData"
    },
    cards: {
        getCards: "gascardreview/getPosts",
        moderateCards : "gascardreview/moderatePosts"
    },
    horror: {
        getPosts: "horrorshow/getPosts",
        moderate: "horrorshow/moderatePosts"
    },
    talent: {
        getPosts: "talentshow/getPosts",
        moderate: "talentshow/moderatePosts"

    },
    help: {
        getTableData : "help/getTableData",
        getIssue : "help/getIssue",
        resolveIssue : "help/markResolved",
        unresolveIssue : "help/markUnresolved",
        deleteIssue : "help/deleteIssue",
        sendNotes : "help/sendNotes"
    }
};
