import './App.css';
import './assets/css/main.css';
import GasApp from "./components/GasApp"


function App() {
  return (

      <div className="App">
        <GasApp />
      </div>
  );
}
export default App;
