import React from "react";

import { API } from '../api/APIClient'
import Axios from 'axios'
import {Link} from "react-router-dom";
import DataTable from 'react-data-table-component';
import SimpleAlertDialog, { ModalButton } from '../common/SimpleAlertDialog';


class Issues extends React.Component {

    constructor(props) {
        super(props);

         this.state = {
            issueToShow: undefined,
             issues: [],
            columns:  [
            { name: 'User',  wrap :false, width : '33%',selector: row => row.name, sortable: true },
            { name: 'Issue', wrap :true, selector: row => row.description.replace(/<P>/g, '\n'), sortable: true},
        ],
            page : 1,
            limit : 10,
            totalRows : 0,
            showall: 0,
            modalOpen: false,
            modalTitle: "",
            modalMessage: "",
            modalButtons: [],
            notes: ""
        };

        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.closeIssue = this.closeIssue.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.resolveIssue = this.resolveIssue.bind(this);
        this.unresolveIssue = this.unresolveIssue.bind(this);
        this.deleteIssue = this.deleteIssue.bind(this);
        this.sendNotes = this.sendNotes.bind(this);

    }



    componentDidMount()
    {
        this.getIssues();
    }


    showAlertModal(title, message, buttons) {
        this.setState({
            modalOpen: true,
            modalTitle: title,
            modalMessage: message,
            modalButtons: buttons
        });
    }

    dismissAlertModal() {
        this.setState({
            modalOpen: false
        });
    }

    getIssues()
    {
        const formData = new FormData();
        formData.append('page', this.state.page-1);
        formData.append('limit', this.state.limit);
        formData.append('showall', this.state.showall);

        Axios.post(API.help.getTableData, formData)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        totalRows: res.data.data.recordsTotal,
                        issues: res.data.data.data
                    });
                }
            })
            .catch(error => {
                console.error("Error fetching issues:", error);
            });
    }


    getIssueDetails(issueId)
    {
        const formData = new FormData();
        formData.append('issueId', issueId);

        Axios.post(API.help.getIssue, formData)
            .then((res) => {
                if (res.data.success) {
                    this.setState({
                        issueToShow: res.data.data,
                        notes : ""
                    });
                }
            })
            .catch(error => {
                console.error("Error fetching issues:", error);
            });
    }

    resolveIssue()
    {
        const formData = new FormData();
        formData.append('issueId', this.state.issueToShow.issue.id);

        Axios.post(API.help.resolveIssue, formData)
            .then((res) => {
                if (res.data.success) {
                    this.setState((prevState) => ({
                        issueToShow: {
                            ...prevState.issueToShow,
                            issue: {
                                ...prevState.issueToShow.issue,
                                resolved: 1
                            }
                        }
                    }));
                }
            })
            .catch(error => {
                console.error("Error fetching issues:", error);
            });
    }


    unresolveIssue()
    {
        const formData = new FormData();
        formData.append('issueId', this.state.issueToShow.issue.id);

        Axios.post(API.help.unresolveIssue, formData)
            .then((res) => {
                if (res.data.success) {
                    this.setState((prevState) => ({
                        issueToShow: {
                            ...prevState.issueToShow,
                            issue: {
                                ...prevState.issueToShow.issue,
                                resolved: 0
                            }
                        }
                    }));
                }
            })
            .catch(error => {
                console.error("Error fetching issues:", error);
            });
    }


    handleFilterChange(event) {
        this.setState({ showall: event.target.value, page: 1 }, () => this.getIssues());
    }

    handlePerRowsChange(newPerPage, page) {
        this.setState({ limit: newPerPage, page: 1 }, () => this.getIssues());
    }


    handlePageChange(page) {
        this.setState({page: page }, () => this.getIssues());
    }

    handleSelection(row, event) {
        this.getIssueDetails(row.id)
    }

    closeIssue() {
        this.setState({ issueToShow: undefined, notes : "" } );

    }

    deleteIssue() {
        this.showAlertModal(
            "Deletion Confirmation",
            (<div>Are you sure you wish to delete this issue? This action cannot be undone.</div>),
            [{ label: "Yes, Delete Now", onClick: () => this.deleteIssueAPI() }, { label: "Cancel", onClick: () => this.dismissAlertModal() }]
        );
    }


    sendNotes() {
        this.showAlertModal(
            "Send Now?",
            (<div>Send this message to the user's app notifications now?</div>),
            [{ label: "OK", onClick: () => this.sendIssueNotification() }, { label: "Cancel", onClick: () => this.dismissAlertModal() }]
        );
    }


    handleNotesChange = (event) => {
        this.setState({ notes: event.target.value });
    };


    sendIssueNotification(){

        this.dismissAlertModal()

        const formData = new FormData();
        formData.append('issueId', this.state.issueToShow.issue.id);
        formData.append('notes', this.state.notes);

        Axios.post(API.help.sendNotes, formData)
            .then((res) => {
                if (res.data.success) {
                    this.setState((prevState) => ({
                        issueToShow: {
                            ...prevState.issueToShow,
                            issue: {
                                ...prevState.issueToShow.issue,
                                description: res.data.success.description
                            }
                        }
                    }));
                }
            })
            .catch(error => {
                console.error("Error deleting issue:", error);
            });





        this.showAlertModal(
            "Notification sent!",
            (<div>Notification was sent to the user.</div>),
            [{ label: "OK", onClick: () => this.dismissAlertModal() }]
        );
    }


    deleteIssueAPI() {
        this.dismissAlertModal()

        const formData = new FormData();
        formData.append('issueId', this.state.issueToShow.issue.id);

        Axios.post(API.help.deleteIssue, formData)
            .then((res) => {
                if (res.data.success) {
                    this.closeIssue();
                }
            })
            .catch(error => {
                console.error("Error deleting issue:", error);
            });


    }

    render() {

        if (this.state.issueToShow !== undefined){
            return (
                <div className="gas-cards">
                    <header className="header">
                        <Link to={"/"} ><div className="menu-icon"  >
                            <i className="fas fa-home"></i>
                        </div></Link>

                        <div className="big-logo"></div>

                        <div className="logout-icon" onClick={this.props.logoutHandler}>
                            {/* Logout icon */}
                            <i className="fas fa-sign-out-alt"></i>
                        </div>
                    </header>

                    <div  className="gas-cards-content" style={{width : "100%"}}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <span style={{ flex: 1 }} className="ga-button" onClick={this.closeIssue}>Back</span>
                            {this.state.issueToShow.issue.resolved ? <span style={{ flex: 1 }} className="ga-button  red" onClick={this.unresolveIssue}>Mark as Unresolved</span> :
                                <span style={{ flex: 1 }}  className="ga-button  red" onClick={this.resolveIssue}>Mark as Resolved</span>}
                        </div>




                        <h1>Issue Details </h1>

                        <div className="issuedetail">
                            <div><span className="title">Issue Type:</span> <span>{this.state.issueToShow.issue.type ? this.state.issueToShow.issue.type : "Gernal Enquiry"}</span></div>
                            <div><span className="title">User's Name:</span> <span>{this.state.issueToShow.issue.name}</span></div>
                            <div><span className="title">Contact Email:</span> <span>{this.state.issueToShow.issue.email}</span></div>
                            <div><span className="title">Platform:</span> <span>{this.state.issueToShow.issue.platform}</span></div>
                            <div><span className="title">OS Version:</span> <span>{this.state.issueToShow.issue.OSVersion}</span></div>
                            <div><span className="title">Device:</span> <span>{this.state.issueToShow.issue.device}</span></div>
                            <div><span className="title">App Version:</span> <span>{this.state.issueToShow.issue.appVersion}</span></div>
                            <div><span className="title">Status:</span> <span>{this.state.issueToShow.issue.resolved ? "Resolved" : "Unresoled"}</span></div>
                            <div><span className="title">Description of Issue:</span> <span>{this.state.issueToShow.issue.description.replace(/<P>/g, '\n') }</span></div>
                            <div><span className="title">Photos:</span> <span></span></div>
                            <div>
                                {this.state.issueToShow.images.map((image) => (
                                    <div key={image.id}>
                                        <a href={`/${image.publicFullPath}`} target="_blank" rel="noopener noreferrer">
                                            Click to view photo
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <div><span className="title">Videos:</span> <span></span></div>
                            <div>
                                {this.state.issueToShow.videos.map((image) => (
                                    <div key={image.id}>
                                        <a href={`/${image.publicFullPath}`} target="_blank" rel="noopener noreferrer">
                                            Click to view photo
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <div><span className="title">Comments to send to user:</span> </div>
                            <div>
                            <textarea
                                style={{ width: "95%" }}
                                className="form-control"
                                rows="10"
                                id="user_notes"
                                placeholder="Add reply here ..."
                                value={this.state.notes}
                                onChange={this.handleNotesChange}></textarea>
                            </div>
                            <div className="ga-button " onClick={this.sendNotes}>Send to user</div>

                        </div>

                        <div className="ga-button red" onClick={this.deleteIssue}>Delete this Issue</div>

                    </div>


                    <SimpleAlertDialog
                        open={this.state.modalOpen}
                        title={this.state.modalTitle}
                        message={this.state.modalMessage}
                    >
                        {
                            this.state.modalButtons.map((button, index) => (
                                <ModalButton
                                    key={index} // Add a key when mapping
                                    label={button.label}
                                    onClick={button.onClick}
                                    className={button.className}
                                />
                            ))
                        }
                    </SimpleAlertDialog>


                </div>
            );
        } else {
            return (
                <div className="gas-cards">
                    <header className="header">
                        <Link to={"/"} ><div className="menu-icon"  >
                            <i className="fas fa-home"></i>
                        </div></Link>

                        <div className="big-logo"></div>

                        <div className="logout-icon" onClick={this.props.logoutHandler}>
                            {/* Logout icon */}
                            <i className="fas fa-sign-out-alt"></i>
                        </div>
                    </header>

                    <div  className="gas-cards-content">
                        <h1>App Issues Submitted </h1>

                        <div style={{ marginBottom: "10px" }}>
                            <label>
                                <select value={this.state.filter} onChange={this.handleFilterChange}>
                                    <option value="0">Unresolved Issues</option>
                                    <option value="1">All Issues</option>
                                </select>
                            </label>
                        </div>

                        <div className="cards">

                            <DataTable
                                columns={this.state.columns}
                                data={this.state.issues}
                                pagination
                                paginationServer
                                paginationTotalRows={this.state.totalRows}
                                onChangeRowsPerPage={this.handlePerRowsChange}
                                onChangePage={this.handlePageChange}
                                onRowClicked={this.handleSelection}
                                highlightOnHover
                            />

                        </div>

                    </div>



                </div>
            );
        }

    }
}

export default Issues;
