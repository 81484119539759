import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginScreen from './login/LoginScreen'
import { APIHelper } from './api/APIClient'
import Horror from "./horror/Horror";
import Talent from "./talent/Talent";
import GasCard from "./card/GasCard";
import Issues from "./help/Issues";
import Dashboard from "./dashboard/Dashboard";

const  USER_KEY = 'ga_cms_user';  // Define the constant

class GasApp extends React.Component {
    constructor(props) {
        super(props);

        var user = null;
        const usrStr = localStorage.getItem(USER_KEY);
        if (usrStr != null) {
            user = JSON.parse(usrStr)

//            alert("setting to " + user.id + " " + user.token);
            APIHelper.setAuthDetails(user.token, user.id);

        }

        this.state = {user: user};
    }

    componentDidMount() {
        APIHelper.onAuthFailed(() => {
            this.handleLogout();
            window.location.href = "/";
        });
    }

    updateUserObject(user) {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
        this.setState({user: user});
    }

    handleLogin(user) {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
        this.setState({user: user});
        APIHelper.setAuthDetails(user.token, user.id);
        document.location.href = "/";
    }

    handleLogout() {
        localStorage.removeItem(USER_KEY);
        this.setState({user: null});
        APIHelper.removeAuthDetails();


    }

    render() {


        if (this.state.user == null) {
            return (
                <BrowserRouter>
                    <Routes>
                        <Route
                            path='/'
                            element={<LoginScreen
                                loginHandler={(user) => this.handleLogin(user)}
                            />}
                        />
                    </Routes>
                </BrowserRouter>
            );

        } else {
            return (
                <BrowserRouter>
                    <Routes>
                        <Route path="horror" element={<Horror />} />
                        <Route path="talent" element={<Talent />} />
                        <Route path="card" element={<GasCard />} />
                        <Route path="issues" element={<Issues />} />
                        <Route path="/" element={<Dashboard user={this.state.user} logoutHandler={() => this.handleLogout()} />} />
                    </Routes>
                </BrowserRouter>



            );
        }
    }
}

export default GasApp;