import React from 'react';
import { API } from '../api/APIClient'
import Axios from 'axios'
import {Link} from "react-router-dom";



class Horror extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            postData : [],
            rejectedIds: []
        };
        this.handleChange = this.handleChange.bind(this);

    }


    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            this.getPosts();
        });


    }

    componentDidMount()
    {
        this.getPosts();
    }


    getPosts()
    {
        const formData = new FormData();
        formData.append('order', "DESC");
        formData.append('count', 5);
        Axios
            .post(API.horror.getPosts, formData)
            .then((res) => {

                if (res.data.success) {

                    this.setState({
                        postData: res.data.data

                    })
                }
            }).catch(error => {

        });
    }



    cardClicked(theId) {

        this.setState((prevState) => {
            if (prevState.rejectedIds.includes(theId)) {
                return {
                    rejectedIds: prevState.rejectedIds.filter(id => id !== theId)
                };
            } else {
                return {
                    rejectedIds: [...prevState.rejectedIds, theId]
                };
            }
        });
    }


    moderate() {

        const { postData, rejectedIds } = this.state;

        const acceptedIds = postData
            .filter(card => !rejectedIds.includes(card.id))  // Exclude cards in rejectedIds
            .map(card => card.id);


            const formData = new FormData();

            formData.append('postIds', (JSON.stringify(rejectedIds)));

            formData.append('status', 0);

            // Send data to the server
            Axios
                .post(API.horror.moderate, formData)
                .then((res) => {
                    if (res.data.success) {
                        this.setState({
                            rejectedIds: []
                        });
                        this.getPosts();
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });




            const formDataAccept = new FormData();

            formDataAccept.append('postIds', (JSON.stringify(acceptedIds)));

            formDataAccept.append('status', 1);

            // Send data to the server
            Axios
                .post(API.horror.moderate, formDataAccept)
                .then((res) => {
                    if (res.data.success) {

                        this.getPosts();
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });

    }




    render() {
        return (
            <div className="gas-cards">
                <header className="header">
                    <Link to={"/"} ><div className="menu-icon"  >
                        <i className="fas fa-home"></i>
                    </div></Link>

                    <div className="big-logo"></div>

                    <div className="logout-icon" onClick={this.props.logoutHandler}>
                        {/* Logout icon */}
                        <i className="fas fa-sign-out-alt"></i>
                    </div>
                </header>

                <div  className="gas-cards-content">
                    <h1>Horror Show</h1>
                    <div className="cards">

                    {this.state.postData.map((card) => {

                        return (<div className="photo-card" onClick={() => this.cardClicked(card.id)}>
                            <div>{card.givenName} {card.familyName}</div>
                            <div>Location: {card.location}</div>
                            <div>{card.description}</div>
                            {this.state.rejectedIds.includes(card.id)  ? <span  className="reject-low">Reject</span> : <span  className="approve-low">Approve</span>}
                            <img src={card.image}/>
                        </div>);
                    })}
                    </div>
                    <div className="ga-button " onClick={() => this.moderate()} >Save</div>

                </div>



            </div>
        );
    }
}

export default Horror;
